import React, { createContext, useState } from 'react';

// Create the context
export const PaginationContext = createContext();

// Create a provider component
const PaginationProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = (totalItems) => Math.ceil(totalItems / itemsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (totalItems) => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages(totalItems)));
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = (totalItems) => {
    setCurrentPage(totalPages(totalItems));
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  return (
    <PaginationContext.Provider
      value={{
        currentPage,
        itemsPerPage,
        totalPages,
        handlePreviousPage,
        handleNextPage,
        handleFirstPage,
        handleLastPage,
        handleItemsPerPageChange,
        setItemsPerPage
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider ;
