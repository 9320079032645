import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mealName: "",
  mealCategory: "",
  mealBenefits: "",
  mealImage: "",
  mealDescription: "",
};
const mealSlice = createSlice({
  name: "meal",
  initialState,
  reducers: {
    setMealName: (state, action) => {
      state.mealName = action.payload;
    },

    setMealDescription: (state, action) => {
      state.mealDescription = action.payload;
    },
    setMealBenefits: (state, action) => {
      state.mealBenefits = action.payload;
    },
    setMealCategory: (state, action) => {
      state.mealCategory = action.payload;
    },
    setMealImage: (state, action) => {
      state.mealImage = action.payload;
    },
  },
});
export const {
  setMealName,
  setMealDescription,
  setMealBenefits,
  setMealCategory,
  setMealImage,
} = mealSlice.actions;
export default mealSlice;
