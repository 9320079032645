/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Table, {
  SelectColumnFilter,
} from "../../../components-general/table/table";
import { FaUserPlus } from "react-icons/fa";
import { ApiGetContext } from "../../../context/apiCalls/apiGetContext";
import ReactLoading from "react-loading";
import { ColorButton } from "../../../components-general/buttons/coloredButton";
import CreateAdminModal from "./createAdminModal";

const UsersHomePage = () => {
  const { fetchAllAdmins } = useContext(ApiGetContext);
  const [isLoading, setIsLoading] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = useState(0);

  const handleOpen = () => setOpen(true);

  console.log(error);

  console.log(adminData);
  useEffect(() => {
    fetchAdmins();
  }, [triggerFetch]);

  const fetchAdmins = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllAdmins();
      if (result.success) {
        setAdminData(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },

      {
        Header: "Role",
        accessor: "adminRole",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    []
  );

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ReactLoading type="spin" color="#00a99d" height={"5%"} width={"5%"} />
      </div>
    );
  }
  return (
    <div className="p-5 w-full h-screen">
      <div className="mt-10 mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={handleOpen}
          variant="contained"
          startIcon={<FaUserPlus />}
        >
          Add Admin
        </ColorButton>
      </div>
      <CreateAdminModal
        open={open}
        setTriggerFetch={setTriggerFetch}
        setOpen={setOpen}
      />
      <Table columns={columns} data={adminData} />
    </div>
  );
};

export default UsersHomePage;
