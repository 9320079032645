import { createBrowserRouter } from "react-router-dom";
import Login from "../features/login/login";
import Layout from "../features/layout/layout";

import ArticlesHomePage from "../features/article/screens/articlesHome";
import AddNewArticles from "../features/article/screens/addNewArticle";
import GroceryHome from "../features/groceries/groceryHome";
import AddNewGrocery from "../features/groceries/addNewGrocery";
import Dashboard from "../features/dashboard/screens/dashboard";
import { ProtectedRoute } from "./protectedRoutes";
import AddNewMeal from "../features/meals/screens/addNewMeal";
import MealHome from "../features/meals/screens/mealHome";
import RecipeHome from "../features/recipe/screens/recipeHome";
import AddNewRecipe from "../features/recipe/screens/addNewRecipe";
import UsersHomePage from "../features/users/screens/users";
import EmployeeHomePage from "../features/users/screens/employee";
import ManageClinicianHome from "../features/clinicians/manageClinician/manageClinicians";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },

      {
        path: "articles",
        element: <ArticlesHomePage />,
      },

      {
        path: "articles/new-article",
        element: <AddNewArticles />,
      },
      {
        path: "grocery",
        element: <GroceryHome />,
      },
      {
        path: "grocery/new-grocery",
        element: <AddNewGrocery />,
      },
      {
        path: "meals",
        element: <MealHome />,
      },
      {
        path: "meals/new-meal",
        element: <AddNewMeal />,
      },
      {
        path: "recipe",
        element: <RecipeHome />,
      },
      {
        path: "recipe/new-recipe",
        element: <AddNewRecipe />,
      },
      {
        path: "users",
        element: <UsersHomePage />,
      },

      {
        path: "employees",
        element: <EmployeeHomePage />,
      },
      {
        path: "clinicians",
        element: <ManageClinicianHome />,
      },
    ],
  },
]);

export default router;
