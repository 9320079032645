import React from "react";
import CustomUploadImage from "../../../components-general/imageUpload/customUploadImage";
import CustomSelect from "../../../components-general/selector/customSelect";
import CustomTextArea from "../../../components-general/textArea/customTextArea";
import CustomTextInput from "../../../components-general/textInput/customTextInput";

import Editor from "./editor";

export default function InputArticle({
  categories,
  authors,
  setTitle,
  setPreviewImage,
  setCoverImage,
  setAuthor,
  setCategory,
  setContent,
  setSummary,
  title,
  category,
  author,
  summary,
  content,
}) {
  const handleTitle = (e) => {
    setTitle(e.target.value);
    //dispatch(setArticleTitle(e.target.value));
  };

  const handleImage = (e) => {
    setCoverImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    //dispatch(setArticleImage(e.target.files[0]));
    //dispatch(setPreviewImage(URL.createObjectURL(e.target.files[0])));
  };

  const handleAuthor = (e) => {
    setAuthor(e.target.value);
    // dispatch(setArticleAuthor(e.target.value));
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
    // dispatch(setArticleCategory(e.target.value));
  };

  const handleSummary = (e) => {
    setSummary(e.target.value);
    //dispatch(setArticleSummary(e.target.value));
  };
  const handleContent = (value, viewUpdate) => {
    setContent(value);
    //dispatch(setArticleContent(value));
  };

  return (
    <div>
      <div className="m-3 mb-8">
        <CustomUploadImage label="Cover Image" onChange={handleImage} />
      </div>
      <form>
        <div className="m-3 mb-8">
          <CustomTextInput
            value={title}
            onChange={handleTitle}
            placeholder="Enter title here ..."
            label="Title "
          />
        </div>
        <div>
          <div className="m-3 mb-8">
            <CustomSelect
              items={authors}
              onChange={handleAuthor}
              value={author}
              label="Author"
              defaultText="Select Author "
            />
          </div>
          <div className="m-3 mb-8">
            <CustomSelect
              items={categories}
              onChange={handleCategory}
              value={category}
              label="Category "
              defaultText="Select Category "
            />
          </div>
        </div>
        <div className="m-3 mb-8">
          <CustomTextArea
            value={summary}
            onChange={handleSummary}
            placeholder="Describe the recipe here"
            label="Recipe Description"
          />
        </div>
        <div className="m-3 mb-8">
          <Editor onChange={handleContent} value={content} label="Content" />
        </div>
      </form>
    </div>
  );
}
