import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groceryName: "",
  groceryDescription: "",
  groceryIngredients: "",
  groceryImage: "",
  groceryCategory: "",
};
const grocerySlice = createSlice({
  name: "grocery",
  initialState,
  reducers: {
    setGroceryName: (state, action) => {
      state.groceryName = action.payload;
    },

    setGroceryDescription: (state, action) => {
      state.groceryDescription = action.payload;
    },
    setGroceryIngredients: (state, action) => {
      state.groceryIngredients = action.payload;
    },
    setGroceryCategory: (state, action) => {
      state.groceryCategory = action.payload;
    },
    setGroceryImage: (state, action) => {
      state.groceryImage = action.payload;
    },
  },
});
export const {
  setGroceryName,
  setGroceryDescription,
  setGroceryIngredients,
  setGroceryImage,
  setGroceryCategory,
} = grocerySlice.actions;
export default grocerySlice;
