import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import ApiGetContextProvider from "./context/apiCalls/apiGetContext";
import ApiPostContextProvider from "./context/apiCalls/apiPostContext";
import ApiUpdateContextProvider from "./context/apiCalls/apiUpdateContext";
import AuthContextProvider from "./context/authentication/authContext";
import PaginationProvider from "./context/pagination";
import SearchProvider from "./context/searchinputcontext";
import store, { persistor } from "./redux/store";
import router from "./routers/routes";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SearchProvider>
          <PaginationProvider>
            <ApiUpdateContextProvider>
              <ApiPostContextProvider>
                <ApiGetContextProvider>
                  <AuthContextProvider>
                    <RouterProvider router={router} />
                  </AuthContextProvider>
                </ApiGetContextProvider>
              </ApiPostContextProvider>
            </ApiUpdateContextProvider>
          </PaginationProvider>
        </SearchProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
