import React from "react";
import CustomSelect from "../../../components-general/selector/customSelect";
import CustomTextArea from "../../../components-general/textArea/customTextArea";
import CustomTextInput from "../../../components-general/textInput/customTextInput";
import CustomUploadImage from "../../../components-general/imageUpload/customUploadImage";

import Editor from "../../article/components/editor";

export default function InputRecipe({
  cookingTimes,
  setName,
  setPreviewImage,
  setCoverImage,
  setCookingTime,
  setIngredient,
  setDescription,
  name,
  description,
  ingredient,
  cookingTime,
}) {
  const handleRecipeName = (e) => {
    setName(e.target.value);
    //dispatch(setRecipeName(e.target.value));
  };

  const handleRecipeImage = (e) => {
    setCoverImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    //dispatch(setRecipeImage(URL.createObjectURL(e.target.files[0])));
  };

  const handleCookingTime = (e) => {
    setCookingTime(e.target.value);
    // dispatch(setCookingTime(e.target.value));
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    //dispatch(setRecipeDescription(e.target.value));
  };
  const handleIngredient = (value, viewUpdate) => {
    setIngredient(value);
    //dispatch(setRecipeIngredients(value));
  };

  return (
    <div>
      <div className="m-3 mb-8">
        <CustomUploadImage label="Image" onChange={handleRecipeImage} />
      </div>
      <form>
        <div className="m-3 mb-8">
          <CustomTextInput
            value={name}
            onChange={handleRecipeName}
            placeholder="Enter recipe name ..."
            label="Name"
          />
        </div>
        <div>
          <div className="m-3 mb-8">
            <CustomSelect
              items={cookingTimes}
              onChange={handleCookingTime}
              value={cookingTime}
              label="Cooking Time"
              defaultText="Select Cooking Time "
            />
          </div>
        </div>
        <div className="m-3 mb-8">
          <CustomTextArea
            value={description}
            onChange={handleDescription}
            placeholder="Describe the recipe here"
            label="Description"
          />
        </div>
        <div className="m-3 mb-8">
          <Editor
            onChange={handleIngredient}
            value={ingredient}
            label="Ingredients & Cooking Process"
          />
        </div>
      </form>
    </div>
  );
}
