import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";

export default function DisplayGrocery({
  name,
  description,
  ingredient,
  category,
  previewImage,
}) {
  return (
    <div>
      <p className="mb-5 ml-10 text-gray-400 font-normal text-base ">
        Grocery item will be displayed here
      </p>
      {previewImage && (
        <div className="mb-5 mt-3">
          <img src={previewImage} width={400} height={200} alt="article" />
        </div>
      )}
      {name && (
        <p className="mb-5 font-bold text-sm ">
          Grocery Name:
          <span className="font-normal ml-2 text-base">{name}</span>
        </p>
      )}
      {category && (
        <p className="mb-5 font-bold text-sm ">
          Grocery Category:
          <span className="font-normal ml-2 text-base">{category}</span>
        </p>
      )}

      {description && (
        <div className="mb-5">
          <p className="font-bold text-sm mb-1">Description</p>
          <p>{description}</p>
        </div>
      )}

      {ingredient && (
        <div className="mb-5">
          <p className="font-bold text-sm mb-1">Ingredients & Benefits</p>
          <MarkdownPreview source={ingredient} />
        </div>
      )}
    </div>
  );
}
