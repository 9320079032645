import React, { useContext, useEffect, useState } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";

import DisplayMeal from "../componets/displayMeal";
import InputMeal from "../componets/inputMeal";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import VerifiedIcon from "@mui/icons-material/Verified";
import { ColorLoadingButton } from "../../users/screens/addEmployeeModal";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import { ApiPostContext } from "../../../context/apiCalls/apiPostContext";
import { ApiUpdateContext } from "../../../context/apiCalls/apiUpdateContext";

export default function AddNewMeal() {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [error, setError] = React.useState("");
  const [update, setUpdate] = useState(false);
  const [id, setID] = useState(false);
  console.log(error);
  const { postMeals, approveMeal } = useContext(ApiPostContext);
  const { updateMeals } = useContext(ApiUpdateContext);

  const notify = () => {
    toast.success("Successfully Saved !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const notifyError = () => {
    toast.error("Error Saving !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      const data = location.state;
      setName(data?.name);
      setCategory(data?.category);
      setDescription(data?.description);
      setIngredient(data?.ingredient);
      setPreviewImage(data?.imageUrl);
      setID(data?.id);
      setUpdate(true);
      console.log("Data from here");
      console.log(data);
    }
  }, [location]);

  const mealCategories = [
    { title: "Fruit", value: "Fruit" },
    { title: "Vegetables", value: "Vegetables" },
  ];
  const handleClick = async (event) => {
    event.preventDefault();
    const details = {
      name: name,
      description: description,
      ingredient: ingredient,
      category: category,
    };
    const data = new FormData();

    data.append("details", JSON.stringify(details));

    data.append("image", coverImage);

    setIsLoading(true);

    try {
      const result = await postMeals(data);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let details = {};
    if (update) {
      details = {
        name: name,
        description: description,
        ingredient: ingredient,
        category: category,
        id: id,
      };
    } else {
      details = {
        name: name,
        description: description,
        ingredient: ingredient,
        category: category,
      };
    }

    const data = new FormData();

    data.append("details", JSON.stringify(details));

    data.append("image", coverImage);

    setIsLoading(true);

    try {
      const result = await updateMeals(data);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    setIsApproving(true);
    try {
      const result = await approveMeal(id);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        if (result.error) {
          setError("Could not approve grocery");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsApproving(false);
    }
  };

  return (
    <div className="w-full p-4 h-screen overflow-y-auto custom-scrollbar">
      <div className="mt-10 flex flex-row items-center justify-between">
        <div className="mt-5">
          <p className="font-bold m-2">Add Grocery Item</p>
        </div>
        <div className="mt-5 w-24">
          <button className="bg-teal-500 w-24 text-center p-1 rounded-md text-white">
            Save
          </button>
        </div>
      </div>
      <div className=" flex flex-row ">
        <div className="mt-5">
          <InputMeal
            name={name}
            category={category}
            description={description}
            ingredient={ingredient}
            categories={mealCategories}
            setCategory={setCategory}
            setName={setName}
            setCoverImage={setCoverImage}
            setPreviewImage={setPreviewImage}
            setDescription={setDescription}
            setIngredient={setIngredient}
          />
        </div>
        <div className="mt-5 mx-10 ">
          <DisplayMeal
            name={name}
            category={category}
            description={description}
            ingredient={ingredient}
            previewImage={previewImage}
          />
        </div>
      </div>
      <Stack className="mt-5 mx-10 " direction="row" spacing={2}>
        {update ? (
          <ColorLoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<UpdateIcon />}
            variant="outlined"
            onClick={handleUpdate}
          >
            Update
          </ColorLoadingButton>
        ) : (
          <ColorLoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleClick}
          >
            Save
          </ColorLoadingButton>
        )}
        <LoadingButton
          loading={isApproving}
          loadingPosition="start"
          startIcon={<VerifiedIcon />}
          variant="outlined"
          onClick={handleApprove}
        >
          Approve
        </LoadingButton>
      </Stack>
      <ToastContainer />
    </div>
  );
}
