import React from "react";

export default function CustomTextInput({
  onChange,
  value,
  placeholder,
  label,
  type = "text",
}) {
  return (
    <div>
      <p className="font-semibold text-sm mb-3 ">{label}</p>
      <input
        type={type}
        className="w-full outline-none border h-10 rounded-lg shadow-sm  py-2 px-3 text-sm"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
