import React, { createContext } from "react";
import { useSelector } from "react-redux";

export const ApiUpdateContext = createContext();

//const BASEURL = "https://dearxmom-admin-backend.azurewebsites.net";

const ApiUpdateContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.token);
  console.log(token);

  const API_BASE_URL = "https://api.dearxmom.com";
  const UPDATE_ARTICLE = `${API_BASE_URL}/admin/education/update-article`;
  const UPDATE_MEALS = `${API_BASE_URL}/admin/nutrition/update-meal`;
  const UPDATE_GROCERIES = `${API_BASE_URL}/admin/nutrition/update-grocery`;
  const UPDATE_RECIPES = `${API_BASE_URL}/admin/nutrition/update-recipe`;

  const updateRecipes = async (data) => {
    try {
      const response = await fetch(UPDATE_RECIPES, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const updateGroceries = async (data) => {
    try {
      const response = await fetch(UPDATE_GROCERIES, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const updateMeals = async (data) => {
    try {
      const response = await fetch(UPDATE_MEALS, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const updateArticle = async (data) => {
    try {
      const response = await fetch(UPDATE_ARTICLE, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };
  return (
    <ApiUpdateContext.Provider
      value={{
        updateRecipes,
        updateGroceries,
        updateMeals,
        updateArticle,
      }}
    >
      {children}
    </ApiUpdateContext.Provider>
  );
};

export default ApiUpdateContextProvider;
