import React from "react";
import { Link } from "react-router-dom";

const SideList = ({ icons, handleItemSelect, selectedIconId }) => {
  return (
    <div>
      {icons.map((iconItem) => (
        <div key={iconItem.icon_id}>
          <Link
            className="m-2 mb-4 flex flex-row items-center"
            to={`/${iconItem.routeName}`}
            onClick={() => handleItemSelect(iconItem.icon_id)}
          >
            <div
              className={
                selectedIconId === iconItem.icon_id.toString()
                  ? "text-[#00a99d] ml-2"
                  : "text-gray-500 ml-2"
              }
            >
              {iconItem.icon}
            </div>
            <p
              className={
                selectedIconId === iconItem.icon_id.toString()
                  ? "ml-2 font-semibold text-sm text-[#00a99d]"
                  : "ml-2 text-sm text-gray-700"
              }
            >
              {iconItem.name}
            </p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default SideList;
