import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { AuthContext } from "../../context/authentication/authContext";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/auth/authSlice";
import PasswordInput from "../../components-general/textInput/passwordTextInput";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  console.log(error);
  const navigate = useNavigate();

  const { loginAdmin } = useContext(AuthContext);
  const dispatch = useDispatch();

  const onLoginUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await loginAdmin(email, password);
      if (result.success) {
        console.log("Successful");
        dispatch(loginSuccess());

        navigate("/dashboard");
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ReactLoading
          type="bars"
          color="#00a99d"
          height={"10%"}
          width={"10%"}
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col md:flex-row bg-lightTael h-screen">
      <div className=" md:w-3/6 p-10 text-lightTael">
        <div className=" h-full flex justify-center flex-col ml-4">
          <h3 className="font-medium m-2 mb-5 text-xl">
            Welcome to DeaRx Mom Admin Panel
          </h3>

          <h3 className="m-2 mb-5 font-bold text-mainColor text-2xl">Log in</h3>
          <p className="m-2 text-sm">Employee ID</p>
          <form onSubmit={onLoginUser}>
            <div className="m-2 mb-4">
              <input
                type="email"
                value={email}
                placeholder="Enter employee ID"
                className="focus:outline focus:outline-mainColor pl-3 py-2 border rounded-md md:w-80 bg-white  shadow-lg text-sm"
                onChange={(e) => setUserName(e.target.value.replace(/\s/g, ""))}
              />
            </div>

            <div className="m-2 mb-6">
              <PasswordInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value.replace(/\s/g, ""))}
                placeholder="Password of user ..."
                label="Password "
                width={" md:w-80 bg-white  shadow-lg"}
              />
            </div>
            <div className="">
              <button
                type="submit"
                className=" bg-mainColor m-2 text-center md:w-80 pl-3 py-2 rounded-md  text-white"
              >
                Log In
              </button>
            </div>
            <div className="m-2 ">
              <p className="font-medium text-red-700 text-sm">{error}</p>
            </div>
            <div className="m-2 ">
              <p className="font-medium  text-sm">Forgot your Password?</p>
            </div>
          </form>
        </div>
      </div>
      <div className="md:w-1/2 md:p-0 sm:p-10 bg-lightTael hidden md:block">
        <img
          src="mia.jpg"
          alt="mia"
          className="w-full  h-auto md:h-screen object-cover"
        />
      </div>
    </div>
  );
};

export default Login;
