import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";

export default function DisplayArticle({
  title,
  previewImage,
  author,
  category,
  summary,
  content,
}) {
  return (
    <div className="min-w-96">
      {!title && (
        <p className="mb-5 ml-10 text-gray-400 font-normal text-base ">
          Article will be displayed here
        </p>
      )}

      {title && <p className="mb-5 font-bold text-xl ">{title}</p>}
      {previewImage && (
        <div className="mb-5 mt-3">
          <img src={previewImage} width={400} height={200} alt="article" />
        </div>
      )}
      <div className="flex flex-row justify-between items-center">
        {author && <p className="mb-5 font-bold  text-sm ">{author}</p>}
        {category && (
          <p className="font-bold text-sm text-mainColor ml-3 mb-5">
            {category}
          </p>
        )}
      </div>

      {summary && (
        <div className="mb-5">
          <p className="font-bold text-sm mb-1">Summary</p>
          <p>{summary}</p>
        </div>
      )}

      {content && (
        <div className="mb-5">
          <p className="font-bold text-sm mb-1">Content</p>
          <MarkdownPreview source={content} />
        </div>
      )}
    </div>
  );
}
