import React, { useContext, useEffect, useState } from "react";
import "../../../css/scrollbar.css";
import { ApiPostContext } from "../../../context/apiCalls/apiPostContext";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import UpdateIcon from "@mui/icons-material/Update";
import VerifiedIcon from "@mui/icons-material/Verified";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

import InputArticle from "../components/inputArticle";
import DisplayArticle from "../components/displayArticle";
import { ColorLoadingButton } from "../../users/screens/addEmployeeModal";
import { ApiUpdateContext } from "../../../context/apiCalls/apiUpdateContext";

export const categories = [
  { title: "Nutrition", value: "Nutrition" },
  { title: "Physical Activity", value: "Physical Activity" },
  { title: "Diet", value: "Diet" },
];
export const authors = [
  { title: "Theodora Attah Mabena", value: "Theodora Attah Mabena" },
  { title: "Daniella Akyea Nkrumah", value: "Daniella Akyea Nkrumah" },
  { title: "Mawufemor Dzikunu", value: "Mawufemor Dzikunu" },
  { title: "Maureen Waithira", value: "Maureen Waithira" },
  { title: "Amos Kamau", value: "Amos Kamau" },
  { title: "Abigael Cheptoo", value: "Abigael Cheptoo" },
  {
    title: "Josephine Enyonam Misroame",
    value: "Josephine Enyonam Misroame",
  },
  { title: "Ruth Wanyama", value: "Ruth Wanyama" },
  { title: "Mzimbasi Ngulube", value: "Mzimbasi Ngulube" },
  { title: "Seraphine Arku", value: "Seraphine Arku" },
  { title: "Demo Account", value: "Demo Account" },
];

const AddNewArticles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [author, setAuthor] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [summary, setSummary] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [content, setContent] = useState("");
  const [update, setUpdate] = useState(false);

  const [id, setID] = useState(false);
  const [error, setError] = React.useState("");

  console.log(error);
  const location = useLocation();

  const { postArticle, approveArticle } = useContext(ApiPostContext);
  const { updateArticle } = useContext(ApiUpdateContext);
  const notify = () => {
    toast.success("Successfully Saved !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const notifyError = () => {
    toast.error("Error Saving !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  useEffect(() => {
    if (location?.state) {
      const data = location.state;
      setTitle(data?.title);
      setCategory(data?.category);
      setContent(data?.content);
      setAuthor(data?.author);
      setPreviewImage(data?.previewImage);
      setSummary(data?.summary);
      setID(data?.id);
      setUpdate(true);
      console.log("Data from here");
      console.log(data);
    }
  }, [location]);

  const handleClick = async (event) => {
    event.preventDefault();
    const details = {
      title: title,
      summary: summary,
      content: content,
      author: author,
      category: category,
    };
    const data = new FormData();

    data.append("details", JSON.stringify(details));

    data.append("coverImage", coverImage);

    setIsLoading(true);

    try {
      const result = await postArticle(data);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let details = {};
    if (update) {
      details = {
        title: title.toUpperCase(),
        summary: summary,
        content: content,
        author: author,
        category: category,
        id: id,
      };
    } else {
      details = {
        title: title.toUpperCase(),
        summary: summary,
        content: content,
        author: author,
        category: category,
      };
    }

    const data = new FormData();

    data.append("details", JSON.stringify(details));

    data.append("coverImage", coverImage);

    setIsLoading(true);

    try {
      const result = await updateArticle(data);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        notifyError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    setIsApproving(true);
    try {
      const result = await approveArticle(id);
      if (result.success) {
        console.log("Successful");
        notify();
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsApproving(false);
    }
  };
  return (
    <form>
      <div className="w-full p-4 h-screen overflow-y-auto custom-scrollbar">
        <div className="mt-5 ml-3 ">
          <p className="font-bold text-xl text-mainColor ">Add Article</p>
        </div>
        <div className=" flex flex-row ">
          <div className="mt-5">
            <InputArticle
              onClick={handleClick}
              categories={categories}
              authors={authors}
              isLoading={isLoading}
              setTitle={setTitle}
              setAuthor={setAuthor}
              setCategory={setCategory}
              setContent={setContent}
              setCoverImage={setCoverImage}
              setPreviewImage={setPreviewImage}
              setSummary={setSummary}
              title={title}
              content={content}
              author={author}
              summary={summary}
              coverImage={coverImage}
              previewImage={previewImage}
              category={category}
            />
          </div>
          <div className="mt-5 mx-10 ">
            <DisplayArticle
              title={title}
              summary={summary}
              content={content}
              author={author}
              category={category}
              previewImage={previewImage}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
      <Stack className="mt-5 mx-10 " direction="row" spacing={2}>
        {update ? (
          <ColorLoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<UpdateIcon />}
            variant="outlined"
            onClick={handleUpdate}
          >
            Update
          </ColorLoadingButton>
        ) : (
          <ColorLoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleClick}
          >
            Save
          </ColorLoadingButton>
        )}
        <LoadingButton
          loading={isApproving}
          loadingPosition="start"
          startIcon={<VerifiedIcon />}
          variant="outlined"
          onClick={handleApprove}
        >
          Approve
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default AddNewArticles;
