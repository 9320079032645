/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddItemButton from "../../../components-general/addItemButton/addItemButton";
import Table, { ApprovalPill } from "../../../components-general/table/table";
import { ApiGetContext } from "../../../context/apiCalls/apiGetContext";
import ReactLoading from "react-loading";
import { MenuItem, Menu } from "@mui/material";

export default function RecipeHome() {
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const { fetchAllRecipes } = useContext(ApiGetContext);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchRecipe = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllRecipes();
      if (result.success) {
        setRecipes(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Could not retrieve articles");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchRecipe();
  }, []);

  const onTitleClick = (event, recipe) => {
    // Implement your logic here
    // For example, navigate to the article detail page or display a modal
    setAnchorEl(event.currentTarget); // For the menu position
    setSelectedRecipe(recipe); // Store the clicked article
    //console.log(article);
    // navigate(`/articles/${article.id}`, { state: article });
  };

  const handleView = () => {
    handleClose(); // Close the menu
    if (selectedRecipe) {
      navigate(`/recipe/new-recipe`, { state: selectedRecipe });
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        // Define a custom cell renderer that makes the cell clickable and truncates the text
        Cell: ({ row }) => (
          <div
            onClick={(e) => onTitleClick(e, row.original)}
            style={{
              cursor: "pointer",
              color: "blue",
              //textDecoration: "underline",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px", // Adjust the width as needed
            }}
            title={row.values.name} // Show full title on hover
          >
            {row.values.name}
          </div>
        ),
      },
      {
        Header: "Cooking Time",
        accessor: "cookingTime",
      },
      {
        Header: "Approval",
        accessor: "approved",
        Cell: ApprovalPill,
      },
    ],
    []
  );

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ReactLoading type="spin" color="#00a99d" height={"5%"} width={"5%"} />
      </div>
    );
  }
  return (
    <div className="p-5 w-full h-screen">
      <div className="mt-10 flex flex-row-reverse">
        <AddItemButton pathName="new-recipe" label="Add Recipe" />
      </div>
      <div className="m-2 ">
        <p className="font-medium text-red-700 text-sm">{error}</p>
      </div>
      {recipes.length !== 0 && <Table columns={columns} data={recipes} />}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleView}>View</MenuItem>
      </Menu>
    </div>
  );
}
