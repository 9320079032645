import { useSelector } from "react-redux";
import React, { createContext } from "react";

export const ApiPostContext = createContext();

const ApiPostContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.token);
  console.log(token);

  const API_BASE_URL = "https://api.dearxmom.com";
  const POST_ARTICLE = `${API_BASE_URL}/admin/education/add-article`;
  const POST_MEALS = `${API_BASE_URL}/admin/nutrition/add-meal`;
  const POST_GROCERIES = `${API_BASE_URL}/admin/nutrition/add-grocery`;
  const POST_RECIPES = `${API_BASE_URL}/admin/nutrition/add-recipe`;
  const VERIFY_CLINICIAN = `${API_BASE_URL}/admin/clinician/verify`;
  const APPROVE_ARTICLES = `${API_BASE_URL}/admin/education/approve-article`;
  const APPROVE_GROCERY = `${API_BASE_URL}/admin/nutrition/approve-grocery`;
  const APPROVE_MEAL = `${API_BASE_URL}/admin/nutrition/approve-meal`;
  const APPROVE_RECIPE = `${API_BASE_URL}/admin/nutrition/approve-recipe`;

  const postRecipes = async (data) => {
    try {
      const response = await fetch(POST_RECIPES, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const postGroceries = async (data) => {
    try {
      const response = await fetch(POST_GROCERIES, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const postMeals = async (data) => {
    try {
      const response = await fetch(POST_MEALS, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const postArticle = async (data) => {
    try {
      const response = await fetch(POST_ARTICLE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const verifyClinician = async (id) => {
    try {
      const response = await fetch(VERIFY_CLINICIAN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };
  const approveArticle = async (id) => {
    try {
      const response = await fetch(APPROVE_ARTICLES, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const approveGrocery = async (id) => {
    try {
      const response = await fetch(APPROVE_GROCERY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };
  const approveMeal = async (id) => {
    try {
      const response = await fetch(APPROVE_MEAL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };
  const approveRecipe = async (id) => {
    try {
      const response = await fetch(APPROVE_RECIPE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };
  return (
    <ApiPostContext.Provider
      value={{
        postArticle,
        postMeals,
        postGroceries,
        postRecipes,
        verifyClinician,
        approveArticle,
        approveGrocery,
        approveMeal,
        approveRecipe,
      }}
    >
      {children}
    </ApiPostContext.Provider>
  );
};

export default ApiPostContextProvider;
