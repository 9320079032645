import React from "react";
import CustomUploadImage from "../../../components-general/imageUpload/customUploadImage";
import CustomSelect from "../../../components-general/selector/customSelect";
import CustomTextArea from "../../../components-general/textArea/customTextArea";
import CustomTextInput from "../../../components-general/textInput/customTextInput";

import Editor from "../../article/components/editor";

export default function InputMeal({
  categories,
  setName,
  setPreviewImage,
  setCoverImage,
  setCategory,
  setIngredient,
  setDescription,
  name,
  category,
  description,
  ingredient,
}) {
  const handleName = (e) => {
    setName(e.target.value);

    //dispatch(setMealName(e.target.value));
  };

  const handleImage = (e) => {
    setCoverImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));

    //  dispatch(setMealImage(URL.createObjectURL(e.target.files[0])));
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);

    //  dispatch(setMealCategory(e.target.value));
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);

    //dispatch(setMealDescription(e.target.value));
  };
  const handleBenefits = (value, viewUpdate) => {
    setIngredient(value);

    //dispatch(setMealBenefits(value));
  };
  return (
    <div>
      <div className="m-3 mb-8">
        <CustomUploadImage label="Image" onChange={handleImage} />
      </div>
      <form>
        <div className="m-3 mb-8">
          <CustomTextInput
            value={name}
            onChange={handleName}
            placeholder="Enter meal name ..."
            label="Name"
          />
        </div>

        <div className="m-3 mb-8">
          <CustomSelect
            items={categories}
            onChange={handleCategory}
            value={category}
            label="Category"
            defaultText="Select Category  "
          />
        </div>

        <div className="m-3 mb-8">
          <CustomTextArea
            value={description}
            onChange={handleDescription}
            placeholder="Describe the meal here"
            label="Description"
          />
        </div>
        <div className="m-3 mb-8">
          <Editor
            onChange={handleBenefits}
            value={ingredient}
            label="Ingredients & Benefits"
          />
        </div>
      </form>
    </div>
  );
}
