import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  token: null,
  apiToken: null,
};
const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateApiToken: (state, action) => {
      state.apiToken = action.payload;
    },
    resetToken: state => {
      state.token = null;
      state.apiToken = null;
    },
  },
});
export const {updateToken, resetToken, updateApiToken} = tokenSlice.actions;
export default tokenSlice;
