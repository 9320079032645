import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function PasswordInput({ label, onChange, value, placeholder, width }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div>
      <p className="font-semibold text-sm mb-3 ">{label}</p>
      <div className={`relative ${width} `}>
        <input
          type={isPasswordVisible ? "text" : "password"}
          value={value}
          className={`w-full outline-none border h-10 rounded-lg shadow-sm  py-2 px-3 text-sm`}
          onChange={onChange}
          placeholder={placeholder}
        />
        <FontAwesomeIcon
          icon={isPasswordVisible ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
          className="absolute bottom-3 right-0 mr-3 flex items-center text-gray-500 cursor-pointer"
        />
      </div>
    </div>
  );
}

export default PasswordInput;
