// authSlice.js (assuming this is your authentication slice)

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  // ... other state properties
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isLoggedIn = true;
      // ... handle other state updates, like storing the token
    },
    logout(state) {
      state.isLoggedIn = false;
      // ... handle other state cleanup
    },
    // ... other reducers
  },
});

export const { loginSuccess, logout } = authSlice.actions;
export default authSlice;
