import React from "react";
import "react-quill/dist/quill.snow.css";
import "../../../css/scrollbar.css";
import MarkdownEditor from "@uiw/react-markdown-editor";

const Editor = ({ onChange, value, label }) => {
  console.log(value);
  return (
    <div className=" custom-scrollbar">
      <p className="font-bold text-sm mb-3">{label}</p>
      <MarkdownEditor
        value={value}
        onChange={onChange}
        className=" max-w-screen-md overflow-auto  bg-white "
        style={{ backgroundColor: "white" }}
      />
    </div>
  );
};

export default Editor;
