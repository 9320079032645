/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import AddItemButton from "../../../components-general/addItemButton/addItemButton";
import Table, { ApprovalPill } from "../../../components-general/table/table";
import { ApiGetContext } from "../../../context/apiCalls/apiGetContext";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { MenuItem, Menu } from "@mui/material";

const ArticlesHomePage = () => {
  const [articles, setArticles] = useState([]);
  const { fetchAllArticles } = useContext(ApiGetContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllArticles();
      if (result.success) {
        setArticles(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Could not retrieve articles");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onTitleClick = (event, article) => {
    // Implement your logic here
    // For example, navigate to the article detail page or display a modal
    setAnchorEl(event.currentTarget); // For the menu position
    setSelectedArticle(article); // Store the clicked article
    //console.log(article);
    // navigate(`/articles/${article.id}`, { state: article });
  };
  const handleView = () => {
    handleClose(); // Close the menu
    if (selectedArticle) {
      navigate(`/articles/new-article`, { state: selectedArticle });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Author",
        accessor: "author",
      },
      {
        Header: "Title",
        accessor: "title",
        // Define a custom cell renderer that makes the cell clickable and truncates the text
        Cell: ({ row }) => (
          <div
            onClick={(e) => onTitleClick(e, row.original)}
            style={{
              cursor: "pointer",
              color: "blue",
              //textDecoration: "underline",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px", // Adjust the width as needed
            }}
            title={row.values.title} // Show full title on hover
          >
            {row.values.title}
          </div>
        ),
      },

      {
        Header: "Approval",
        accessor: "approved",
        Cell: ApprovalPill,
      },
      {
        Header: "Category",
        accessor: "category",
      },
    ],
    []
  );

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ReactLoading type="spin" color="#00a99d" height={"5%"} width={"5%"} />
      </div>
    );
  }
  return (
    <div className="p-5 w-full h-screen">
      <div className="mt-10 mb-4 flex flex-row-reverse">
        <AddItemButton pathName="new-article" label="Add Article" />
      </div>
      <div className="m-2 ">
        <p className="font-medium text-red-700 text-sm">{error}</p>
      </div>
      {articles.length !== 0 && <Table columns={columns} data={articles} />}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleView}>View</MenuItem>
      </Menu>
    </div>
  );
};

export default ArticlesHomePage;
