import React from "react";

export default function CustomSelect({
  items,
  value,
  onChange,
  label,
  defaultText,
}) {
  return (
    <div>
      <p className="font-bold text-sm mb-3">{label}</p>

      <div className="">
        <select
          class="bg-white border h-10 w-full shadow-sm  text-sm rounded-lg  block p-2"
          onChange={onChange}
          value={value}
        >
          <option selected>{defaultText}</option>

          {items.map((item, index) => (
            <option key={index} value={item.value}>
              {item.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
