import { useDispatch, useSelector } from "react-redux";
import React, { createContext } from "react";
import { updateToken } from "../../redux/token/tokenSlice";
import { loginSuccess } from "../../redux/auth/authSlice";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.token);
  console.log(token);

  const API_BASE_URL = "https://api.dearxmom.com";
  const LOGIN_ADMIN_ENDPOINT = `${API_BASE_URL}/auth/admin/login`;
  const CREATE_ADMIN_ENDPOINT = `${API_BASE_URL}/auth/admin/create`;
  const ADD_EMPLOYEE = `${API_BASE_URL}/auth/admin/add-employee`;

  const dispatch = useDispatch();

  const loginAdmin = async (email, password) => {
    console.log(email);
    console.log(password);
    const loginData = {
      email: email,
      password: password,
    };
    try {
      const response = await fetch(LOGIN_ADMIN_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });
      console.log(response);

      if (response.ok) {
        const res = await response.json();
        dispatch(updateToken(res));
        dispatch(loginSuccess()); // Dispatch the login success action

        console.log(res);

        return { success: true, data: res };
      } else {
        const res = await response.json();
        return { success: false, error: res.message };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const addEmployee = async (name, email, jobTitle) => {
    const employeeData = {
      name: name,
      email: email,
      jobTitle: jobTitle,
    };
    try {
      const response = await fetch(ADD_EMPLOYEE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(employeeData),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        const res = await response.json();
        if (res.message === "Conflict") {
          return {
            success: false,
            error: "An account has already been created with this email",
          };
        } else {
          return {
            success: false,
            error: "There was an error creating the account. Try again",
          };
        }
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };
  const createAdmin = async (email, role, password) => {
    try {
      const response = await fetch(CREATE_ADMIN_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password, role }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        const res = await response.json();
        if (res.message === "Conflict") {
          return {
            success: false,
            error: "An account has already been created with this email",
          };
        } else {
          return {
            success: false,
            error: "There was an error creating the account. Try again",
          };
        }
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  const postArticle = async (data) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/articles`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: data,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        return { success: true, data: res };
      } else {
        // Handle non-successful response
        const res = await response.json();
        return {
          success: false,
          error: res,
        };
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      return { success: false, error: error.message };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loginAdmin,
        postArticle,
        createAdmin,
        addEmployee,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
