import { createContext, useState } from "react";

export const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  return (
    <SearchContext.Provider
      value={{
        show,
        setShow,
        value,
        setValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
