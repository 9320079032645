import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../dashboard/components/sideBar";

const Layout = () => {
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar with fixed width and vertical scrolling */}
      <div className="" style={{ width: "200px" }}>
        <div className="sticky top-0 h-screen overflow-y-auto">
          <SideBar />
        </div>
      </div>
      {/* Main content area with flex-grow to take the remaining space */}
      <div className="flex-grow overflow-y-auto overflow-x-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
