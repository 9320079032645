import React from "react";

import "../../../css/scrollbar.css";

export default function Dashboard() {
  return (
    <div className="flex justify-center items-center flex-col flex-1 h-screen overflow-y-auto custom-scrollbar">
      <p>You are welcome to DeaRx Admin panel</p>
      <img src="universal.jpg" alt="" className="w-28 mt-3 h-28 " />
    </div>
  );
}
