import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import CustomTextInput from "../../../components-general/textInput/customTextInput";
import { AuthContext } from "../../../context/authentication/authContext";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ColorLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#00a99d",

  "&:hover": {
    backgroundColor: "#E17BAB",
  },
}));

export default function AddEmployeeModal({ open, setOpen, setTriggerFetch }) {
  const handleClose = () => setOpen(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [jobTitle, setJobTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { addEmployee } = useContext(AuthContext);

  console.log(error);

  const notify = () => {
    toast.success("Successfully Added !", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const result = await addEmployee(name, email, jobTitle);
      if (result.success) {
        console.log("Successful");
        notify();
        setName("");
        setEmail("");
        setJobTitle("");
        setTriggerFetch((prev) => prev + 1);
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="font-bold text-lg text-mainColor ">Add Employee</p>

          <form onSubmit={handleAddEmployee}>
            <div className="mt-5 mb-8">
              <CustomTextInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name of employee ..."
                label="Name"
              />
            </div>
            <div className=" mb-8">
              <CustomTextInput
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                placeholder="Email of employee ..."
                label="Email"
              />
            </div>
            <div className=" mb-8">
              <CustomTextInput
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Job title of employee ..."
                label="Job Title"
              />
            </div>
            <Stack direction="row" spacing={2}>
              <ColorLoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type="submit"
              >
                Save
              </ColorLoadingButton>
              <Button onClick={handleClose} variant="outlined" size="medium">
                Cancel
              </Button>
            </Stack>
          </form>
          <ToastContainer />
        </Box>
      </Modal>
    </div>
  );
}
