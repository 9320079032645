import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ColorButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#00a99d",

  "&:hover": {
    backgroundColor: "#E17BAB",
  },
}));
