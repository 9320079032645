import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";

export default function DisplayRecipe({
  name,
  description,
  ingredient,
  cookingTime,
  previewImage,
}) {
  return (
    <div>
      <p className="mb-5 ml-10 text-gray-400 font-normal text-base ">
        Recipe will be displayed here
      </p>
      {previewImage && (
        <div className="mb-5 mt-3">
          <img src={previewImage} width={400} height={200} alt="article" />
        </div>
      )}
      {name && (
        <p className="mb-5 font-bold text-sm ">
          Recipe Name:
          <span className="font-normal ml-2 text-base">{name}</span>
        </p>
      )}
      {cookingTime && (
        <p className="font-bold text-sm mb-5">
          Cooking Name:
          <span className="font-normal ml-2 text-base">
            {cookingTime} minutes
          </span>
        </p>
      )}
      {description && (
        <div className="mb-5">
          <p className="font-bold text-sm mb-1">Description</p>
          <p>{description}</p>
        </div>
      )}

      {ingredient && (
        <div className="mb-5">
          <p className="font-bold text-sm mb-1">
            Ingredients & Cooking Process
          </p>
          <MarkdownPreview source={ingredient} />
        </div>
      )}
    </div>
  );
}
