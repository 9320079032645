import React, { useEffect, useState } from "react";
import { LuFileSpreadsheet } from "react-icons/lu";
import { VscHome } from "react-icons/vsc";
import { FaRegLemon } from "react-icons/fa";
import { GoQuestion } from "react-icons/go";
import { MdLogout } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoFastFood } from "react-icons/io5";
import { BiFoodMenu } from "react-icons/bi";
import { FaUserLarge, FaUserTie, FaUserDoctor } from "react-icons/fa6";

import SideList from "./sideList";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/auth/authSlice";

const homeIcon = [
  {
    icon_id: 1,
    icon: <VscHome size={22} />,
    name: "Home",
    routeName: "dashboard",
  },
];

const adminSectionIcons = [
  {
    icon_id: 2,
    icon: <FaUserTie size={16} />,
    name: "Employee",
    routeName: "employees",
  },
  {
    icon_id: 3,
    icon: <FaUserLarge size={14} />,
    name: "Admins",
    routeName: "users",
  },
  {
    icon_id: 8,
    icon: <FaUserDoctor size={16} />,
    name: "Clinicians",
    routeName: "clinicians",
  },
];

const learningSectionIcons = [
  {
    icon_id: 6,
    icon: <LuFileSpreadsheet />,
    name: "Articles",
    routeName: "articles",
  },
];

const nutritionSectionIcons = [
  {
    icon_id: 10,
    icon: <FaRegLemon />,
    name: "Grocery",
    routeName: "grocery",
  },
  {
    icon_id: 11,
    icon: <IoFastFood />,
    name: "Meal",
    routeName: "meals",
  },
  {
    icon_id: 12,
    icon: <BiFoodMenu />,
    name: "Recipes",
    routeName: "recipe",
  },
];

const SideBar = () => {
  const [learningSection, setLearningSection] = useState(true);
  const [nutritionSection, setNutritionSection] = useState(true);
  const [adminSection, setAdminSection] = useState(true);

  const [selectedIconId, setSelectedIconId] = useState(
    localStorage.getItem("selectedSidebarIconId") ||
      homeIcon[0].icon_id.toString()
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // Update the state when the selected icon ID changes
    localStorage.setItem("selectedSidebarIconId", selectedIconId);
  }, [selectedIconId]);

  const handleItemSelect = (itemId) => {
    setSelectedIconId(itemId.toString()); // Make sure to convert to string
  };

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };
  return (
    <div className="p-2 border-r border-gray-300 w-full   h-full cursor-pointer">
      <div>
        <div className=" ">
          <SideList
            icons={homeIcon}
            handleItemSelect={handleItemSelect}
            selectedIconId={selectedIconId}
          />
        </div>
        <div className="border-b border-gray-300">
          <div
            onClick={() => setLearningSection(!learningSection)}
            className="mt-3 mb-4 flex flex-row items-center justify-between"
          >
            <p className="font-semibold text-sm m-2">Learning</p>
            <div>
              <RiArrowDownSLine />
            </div>
          </div>
          {learningSection && (
            <SideList
              icons={learningSectionIcons}
              handleItemSelect={handleItemSelect}
              selectedIconId={selectedIconId}
            />
          )}
        </div>
        <div className="border-b border-gray-300">
          <div
            onClick={() => setNutritionSection(!nutritionSection)}
            className="mt-3 mb-4 flex flex-row items-center justify-between"
          >
            <p className="font-semibold text-sm m-2">Nutrition</p>
            <div>
              <RiArrowDownSLine />
            </div>
          </div>
          {nutritionSection && (
            <SideList
              icons={nutritionSectionIcons}
              handleItemSelect={handleItemSelect}
              selectedIconId={selectedIconId}
            />
          )}
        </div>

        <div className="border-b border-gray-300">
          <div
            onClick={() => setAdminSection(!adminSection)}
            className="mt-3 mb-4 flex flex-row items-center justify-between"
          >
            <p className="font-semibold text-sm m-2">Admin</p>
            <div>
              <RiArrowDownSLine />
            </div>
          </div>
          {adminSection && (
            <SideList
              icons={adminSectionIcons}
              handleItemSelect={handleItemSelect}
              selectedIconId={selectedIconId}
            />
          )}
        </div>
        <div>
          <div className="m-2 mt-4 flex flex-row items-center">
            <div>
              <GoQuestion />
            </div>
            <p className="ml-2 font-medium text-sm">Help & Support</p>
          </div>
          <button
            onClick={onLogout}
            className="m-2 mt-3 flex flex-row items-center "
          >
            <div>
              <MdLogout />
            </div>
            <p className="ml-2 font-medium text-sm">Log out</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
