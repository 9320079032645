import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recipeName: "",
  cookingTime: "",
  recipeDescription: "",
  recipeIngredients: "",
  recipeImage: "",
  cookingProcess: "",
};
const recipeSlice = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    setRecipeName: (state, action) => {
      state.recipeName = action.payload;
    },
    setCookingTime: (state, action) => {
      state.cookingTime = action.payload;
    },
    setRecipeDescription: (state, action) => {
      state.recipeDescription = action.payload;
    },
    setRecipeIngredients: (state, action) => {
      state.recipeIngredients = action.payload;
    },
    setRecipeImage: (state, action) => {
      state.recipeImage = action.payload;
    },
    setCookingProcess: (state, action) => {
      state.cookingProcess = action.payload;
    },
  },
});
export const {
  setRecipeName,
  setCookingTime,
  setRecipeDescription,
  setRecipeIngredients,
  setRecipeImage,
  setCookingProcess,
} = recipeSlice.actions;
export default recipeSlice;
