import React, { createContext } from "react";
import { useSelector } from "react-redux";
export const ApiGetContext = createContext();

const ApiGetContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.token);
  console.log(token);

  const API_BASE_URL = "https://api.dearxmom.com";
  const GET_ALL_ARTICLE = `${API_BASE_URL}/admin/education/all-articles`;
  const GET_ALL_MEALS = `${API_BASE_URL}/admin/nutrition/all-meals`;
  const GET_ALL_GROCERIES = `${API_BASE_URL}/admin/nutrition/all-groceries`;
  const GET_ALL_RECIPES = `${API_BASE_URL}/admin/nutrition/all-recipes`;
  const GET_ALL_EMPLOYEES = `${API_BASE_URL}/admin/all-employees`;
  const GET_ALL_ADMINS = `${API_BASE_URL}/admin/all-admins`;
  const GET_ALL_CLINICIANS_VERIFY = `${API_BASE_URL}/admin/clinician/all-with-intentions`;
  const GET_A_CLINICIANS = `${API_BASE_URL}/admin/clinician/find`;

  const fetchAllArticles = async () => {
    try {
      const response = await fetch(GET_ALL_ARTICLE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllMeals = async () => {
    try {
      const response = await fetch(GET_ALL_MEALS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllGroceries = async () => {
    try {
      const response = await fetch(GET_ALL_GROCERIES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllRecipes = async () => {
    try {
      const response = await fetch(GET_ALL_RECIPES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllEmployees = async () => {
    console.log(token);
    try {
      const response = await fetch(GET_ALL_EMPLOYEES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };
  const fetchAllAdmins = async () => {
    console.log(token);
    try {
      const response = await fetch(GET_ALL_ADMINS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const fetchAllClinicianVerify = async () => {
    console.log(token);
    try {
      const response = await fetch(GET_ALL_CLINICIANS_VERIFY, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  const findClinician = async (id) => {
    console.log(token);
    try {
      const response = await fetch(`${GET_A_CLINICIANS}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access_token}`,
        },
      });
      // Set the retrieved profile data
      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        return { success: true, data: data };
      } else {
        const data = await response.json();
        console.log("Request failed with status:", data);
        return { success: false, error: data };
      }
    } catch (err) {
      console.error("Error fetching profile:", err.message);
      return {
        success: false,
        error: `Error fetching profile: ${err.message}`,
      };
    }
  };

  //get all data from articles

  return (
    <ApiGetContext.Provider
      value={{
        fetchAllArticles,
        fetchAllMeals,
        fetchAllGroceries,
        fetchAllRecipes,
        fetchAllEmployees,
        fetchAllAdmins,
        fetchAllClinicianVerify,
        findClinician,
      }}
    >
      {children}
    </ApiGetContext.Provider>
  );
};

export default ApiGetContextProvider;
