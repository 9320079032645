import React from "react";
import { Link } from "react-router-dom";
import { IoAdd } from "react-icons/io5";

export default function AddItemButton({
  pathName,
  label,
  icon = <IoAdd size={20} />,
}) {
  return (
    <div>
      <Link
        className="border flex flex-row shadow-md items-center space-x-2 rounded-xl p-2 pr-3  mr-2"
        to={{ pathname: pathName }}
      >
        {icon}
        <button className="text-sm">{label}</button>
      </Link>
    </div>
  );
}
