/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import ReactLoading from "react-loading";
import Table, { StatusPill } from "../../../components-general/table/table";
import { ApiGetContext } from "../../../context/apiCalls/apiGetContext";
import { ColorButton } from "../../../components-general/buttons/coloredButton";
import FindClinicianAndVerifyModal from "./findAndVerifyClinician";

const ManageClinicianHome = () => {
  const { fetchAllClinicianVerify } = useContext(ApiGetContext);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicianData, setClinicianData] = useState([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = useState(0);

  console.log(error);

  useEffect(() => {
    fetchEmployee();
  }, [triggerFetch]);

  const handleOpen = () => setOpen(true);

  const fetchEmployee = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllClinicianVerify();
      if (result.success) {
        setClinicianData(result.data);
        return result.data;
      } else {
        if (result.error) {
          setError("Email or password is incorrect");
        } else {
          setError(result.error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Status",
        accessor: "verificationStatus",
      },
      {
        Header: "Verified",
        accessor: "verified",
        Cell: StatusPill,
      },
    ],
    []
  );

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ReactLoading type="spin" color="#00a99d" height={"5%"} width={"5%"} />
      </div>
    );
  }
  return (
    <div className="p-5 w-full h-screen">
      <div className="mt-10 mb-4 flex flex-row-reverse">
        <ColorButton
          onClick={handleOpen}
          variant="contained"
          startIcon={<FaUserPlus />}
        >
          Verify Clinician
        </ColorButton>
      </div>
      <FindClinicianAndVerifyModal
        open={open}
        setTriggerFetch={setTriggerFetch}
        setOpen={setOpen}
      />
      {clinicianData.length !== 0 && (
        <Table columns={columns} data={clinicianData} />
      )}
    </div>
  );
};

export default ManageClinicianHome;
