import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articleTitle: "",
  articleAuthor: "",
  articleCategory: "",
  articleSummary: "",
  articleImage: "",
  articleContent: "",
  previewImage: "",
};
const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    setArticleTitle: (state, action) => {
      state.articleTitle = action.payload;
    },
    setArticleAuthor: (state, action) => {
      state.articleAuthor = action.payload;
    },
    setArticleCategory: (state, action) => {
      state.articleCategory = action.payload;
    },
    setArticleSummary: (state, action) => {
      state.articleSummary = action.payload;
    },
    setArticleImage: (state, action) => {
      state.articleImage = action.payload;
    },
    setPreviewImage: (state, action) => {
      state.previewImage = action.payload;
    },
    setArticleContent: (state, action) => {
      state.articleContent = action.payload;
    },
  },
});
export const {
  setArticleTitle,
  setArticleAuthor,
  setArticleCategory,
  setArticleSummary,
  setArticleImage,
  setArticleContent,
  setPreviewImage,
} = articleSlice.actions;
export default articleSlice;
